<template>
  <v-container id="all-projects-container" fluid tag="section">
    <v-row>

      <!-- 專案 - 鍠麟 -->
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="500" :elevation="5">
          <a
            href="https://cat-demo.fetnet.net/smartfarm"
            target="_blank"
            class="v-img-link"
          >
            <v-img
              @mouseover="hoverImg1 = true"
              @mouseleave="hoverImg1 = false"
              :class="{ active: hoverImg1 }"
              class="white--text align-end"
              :aspect-ratio="16 / 9"
              position="center top"
              src="@/assets/ui-preview-iot-farm.jpg"
            >
            </v-img>
          </a>
          <v-card-title>鍠麟智能環控系統</v-card-title>
          <v-card-subtitle class="pb-0"> IOT of Smart Farm </v-card-subtitle>

          <v-col class="tag-group">
            <v-chip
              class="my-2 ml-2 mb-0"
              :style="{ background: $vuetify.theme.themes[theme].labelBG }"
              label
              text-color="white"
            >
              <v-icon left color="white"> mdi-label </v-icon>
              Azure Map
            </v-chip>

            <v-chip
              class="my-2 ml-2 mb-0"
              :style="{ background: $vuetify.theme.themes[theme].labelBG }"
              label
              text-color="white"
            >
              <v-icon left color="white"> mdi-label </v-icon>
              IoT Data
            </v-chip>
          </v-col>

          <v-card-actions class="d-flex justify-end align-end">
            <v-btn
              color="primary"
              text
              class="btn-demo-text"
              href="https://cat-demo.fetnet.net/smartfarm"
              target="_blank"
            >
              DEMO SITE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- 專案 - 觀光雲 -->
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="500" :elevation="5">
          <a
            href="https://cat-demo.fetnet.net/tourism"
            target="_blank"
            class="v-img-link"
          >
            <v-img
              @mouseover="hoverImg2 = true"
              @mouseleave="hoverImg2 = false"
              :class="{ active: hoverImg2 }"
              class="white--text align-end"
              :aspect-ratio="16 / 9"
              position="center top"
              src="@/assets/ui-preview-tourism.jpg"
            >
            </v-img>
          </a>

          <v-card-title>全方位景區數位管理平台</v-card-title>
          <v-card-subtitle class="pb-0"> IoT of Smart Tourism </v-card-subtitle>

          <v-col class="tag-group">
            <v-chip
              class="my-2 ml-2 mb-0"
              :style="{ background: $vuetify.theme.themes[theme].labelBG }"
              label
              text-color="white"
            >
              <v-icon left color="white"> mdi-label </v-icon>
              GIS Map
            </v-chip>

            <v-chip
              class="my-2 ml-2 mb-0"
              :style="{ background: $vuetify.theme.themes[theme].labelBG }"
              label
              text-color="white"
            >
              <v-icon left color="white"> mdi-label </v-icon>
              IoT Data
            </v-chip>
          </v-col>

          <v-card-actions class="d-flex justify-end align-end">
            <v-btn
              color="primary"
              text
              class="btn-demo-text"
              href="https://cat-demo.fetnet.net/tourism"
              target="_blank"
            >
              DEMO SITE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- 專案 - 智慧城市 -->
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="500" :elevation="5">
          <a
            href="https://cat-demo.fetnet.net/smartbuilding"
            target="_blank"
            class="v-img-link"
          >
            <v-img
              @mouseover="hoverImg3 = true"
              @mouseleave="hoverImg3 = false"
              :class="{ active: hoverImg3 }"
              class="white--text align-end"
              :aspect-ratio="16 / 9"
              position="center top"
              src="@/assets/ui-preview-smartbuilding.jpg"
            >
            </v-img>
          </a>

          <v-card-title>智慧建築數位管理平台</v-card-title>
          <v-card-subtitle class="pb-0"> IoT of Smart Building </v-card-subtitle>

          <v-col class="tag-group">
            <v-chip
              class="my-2 ml-2 mb-0"
              :style="{ background: $vuetify.theme.themes[theme].labelBG }"
              label
              text-color="white"
            >
              <v-icon left color="white"> mdi-label </v-icon>
              GIS Map
            </v-chip>

            <v-chip
              class="my-2 ml-2 mb-0"
              :style="{ background: $vuetify.theme.themes[theme].labelBG }"
              label
              text-color="white"
            >
              <v-icon left color="white"> mdi-label </v-icon>
              IoT Data
            </v-chip>

            <v-chip
              class="my-2 ml-2 mb-0"
              :style="{ background: $vuetify.theme.themes[theme].labelBG }"
              label
              text-color="white"
            >
              <v-icon left color="white"> mdi-label </v-icon>
              3D Bin Model
            </v-chip>
          </v-col>

          <v-card-actions class="d-flex justify-end align-end">
            <v-btn
              color="primary"
              text
              class="btn-demo-text"
              href="https://cat-demo.fetnet.net/smartbuilding"
              target="_blank"
            >
              DEMO SITE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      hoverImg1: false,
      hoverImg2: false,
      hoverImg3: false
    }
  },
  computed: {
    theme () {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    }
  }
}
</script>

<style>
.v-card__title {
  font-size: 16px;
}
.v-img-link > .v-image {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.btn-demo-text {
  font-weight: bold;
}
.tag-group {
  padding: 0px 12px;
  min-height: 80px;
}
/* 滑鼠移出後反應 */
.v-image__image {
  transition: all 0.4s ease-in-out;
}
/* 滑鼠移上後反應 */
.active > .v-image__image {
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
}
</style>
